import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "prd-l-i" }
const _hoisted_4 = { class: "prd-l-dt" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "prd-nm"
}
const _hoisted_7 = { class: "m-1" }
const _hoisted_8 = { class: "prd-nm" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "prd-l-act" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "hidden md:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Rt = _resolveComponent("Rt")!
  const _component_Bt = _resolveComponent("Bt")!
  const _component_DtView = _resolveComponent("DtView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DtView, {
      value: _ctx.lpds,
      layout: _ctx.layout,
      paginator: true,
      rows: _ctx.setrow,
      sortOrder: _ctx.sortOrder,
      sortField: _ctx.sortField
    }, {
      list: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("a", {
                class: "no-underline",
                href: '/san-pham/'+slotProps.data.slug + '-' + slotProps.data.code
              }, [
                (slotProps.data.shorttitle && slotProps.data.shorttitle != 'null')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("h3", _hoisted_7, _toDisplayString(slotProps.data.shorttitle), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, _toDisplayString(slotProps.data.title), 1)
              ], 8, _hoisted_5),
              _createElementVNode("div", {
                class: "product-description p-2",
                innerHTML: slotProps.data.shortdesc
              }, null, 8, _hoisted_9)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (slotProps.data.qrcode)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    width: "150",
                    height: "150",
                    class: "p-1 flex align-items-center justify-content-center",
                    src: _ctx.url+'/qrcode/' + slotProps.data.qrcode,
                    alt: slotProps.data.title
                  }, null, 8, _hoisted_11))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Rt, {
                  value: _ctx.ratingvl,
                  readonly: true,
                  cancel: false
                }, null, 8, ["value"])
              ]),
              _createVNode(_component_Bt, {
                class: "p-button-warning",
                icon: "pi pi-shopping-cart",
                label: _ctx.t('sproduct.dangky'),
                onClick: ($event: any) => (_ctx.openshopcart(slotProps.data.code)),
                iconPos: _ctx.iconPos
              }, null, 8, ["label", "onClick", "iconPos"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["value", "layout", "rows", "sortOrder", "sortField"])
  ]))
}
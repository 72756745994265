<template>
  <Header />
  <Mainpage @sload="sload"/>
  <template v-if="loading">
  <Footer />
  </template>
</template>

<script>
import Header from '@/layouts/header/Header.vue' // @ is an alias to /src
import Mainpage from '@/layouts/main/section.vue' // @ is an alias to /src
import { defineAsyncComponent } from 'vue'
export default {
  components: {
    Header,
    Mainpage,
    Footer: defineAsyncComponent(() =>
      import('@/layouts/footer/section.vue')
    )
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    sload (vl) {
      this.loading = vl
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<template>
    <div class="w-full bg-color-white" style="height:420px">
        <div class="grid" v-if="!showMessage">
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 h-auto mb-8" v-if="lchatbots" ref="l_chatbot">
              <div  v-for="(item, index) in lwchatbots" :key="index" :id="item.code" class="mt-2">
              <Chip label="Hoàng Thị Yến" image="/img/customer/kh1.jpg" width="50px" height="50px" />
              <div class="card w-11 mt-1">
              <div class="card-container yellow-container overflow-hidden p-2">
              <div>
              <span>{{(lg === 'en' && item.titleen)?item.titleen:((lg === 'de' && item.titlede)?item.titlede:item.title)}}</span>
              </div>
              <div v-if="item.qtype=='product'||item.qtype=='checkbox'">
              <div v-for="(sitem,sindex) in item.answer" :key="sindex" class="field-checkbox">
              <Checkbox :id="sitem.code" :value="sitem.code" v-model="svdata[item.code]" />
               <label :for="sitem.code" v-html="sitem.title"></label>
              </div>
              <div class="mt-4"><Button :label="t('tieptuc')" @click="next(item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
              </div>
              <div v-if="item.qtype=='shopingcart'">
              <div v-for="(sitem, sindex) in item.answer" :key="sindex" class="w-full mt-2 border-bottom-1 border-black-alpha-10">
              <div class="grid" >{{sitem.shorttitle?(sitem.title + ' (' + sitem.shorttitle + ')'):sitem.title }}</div>
              <div class="grid">
              <div class="col-3">
              <InputText class="w-full" style="padding:1px" :id="sitem.code" :min="sitem.min" v-model="svdata[sitem.code]" @input="CuData(item,sitem,index,sindex)"/>
              </div>
              <div class="col-5">x{{ sitem.rcprice }}x{{sitem.tg}}=</div><div class="col-4">{{formatCurrency(sitem.totalprice)}}</div>
              </div>
              </div>
              <div class="mt-5"><Button :label="t('tieptuc')" @click="nextbt(item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
              </div>
              <div v-else-if="item.qtype=='button'">
              <div v-for="(sitem,index) in item.answer" :key="index">
                <div class="mt-2"><Button :label="(lg === 'en' && sitem.titleen)?sitem.titleen:((lg === 'de' && sitem.titlede)?sitem.titlede:sitem.title)" @click="nextbt(sitem)"  class="p-2 p-button-warning w-full" /></div>
               </div>
              </div>
              <div v-else-if="item.qtype=='form'">
              <div v-for="(sitem,sindex) in item.answer" :key="sindex">
              <div v-if="sitem.type=='7'" class="field-checkbox">
              <div>
              <Checkbox :id="sitem.code" :value="sitem.code" v-model="svdata[sitem.code]" />
               <label :for="sitem.code">{{(lg === 'en' && sitem.titleen)?sitem.titleen:((lg === 'de' && sitem.titlede)?sitem.titlede:sitem.title)}}</label>
               </div>
               <div class="mt-4"><Button :label="t('tieptuc')" @click="next(item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
               </div>
               <div v-if="sitem.type=='6'" class="">
              <div>
              <div v-for="(saitem,indexa) in sitem.lsource" :key="indexa" class="mt-4">
               <RadioButton :id="saitem.code" :name="saitem.title" :value="saitem.code" v-model="svdata[sitem.code]" />
               <label :for="saitem.code"> {{saitem.title}}</label>
              </div>
               </div>
               <div class="mt-4"><Button :label="t('tieptuc')" @click="next(item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
               </div>
               <div v-if="sitem.type=='2'||sitem.type=='3'" class="p-float-label">
               </div>
              <div v-if="sitem.type=='4'" >
               </div>
              </div>
              </div>
              <div v-else-if="item.qtype=='radio'">
              <div v-for="(sitem,index) in item.answer" :key="index" class="p-float-label mt-4">
               <RadioButton :id="sitem.code" v-model="svdata[item.code]" />
               <label :for="sitem.code">{{sitem.title}}</label>
              </div>
              </div>
              <div v-else-if="item.qtype=='contact'">
              <div v-for="(sitem,index) in item.answer" :key="index" class="p-float-label mt-4">
              </div>
              <div class="mt-4"><Button label="Trang trước" @click="prev"  icon="pi pi-arrow-left" class="mr-3 p-button-secondary"/><Button :label="t('tieptuc')" @click="next(item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
              </div>
              <div v-else-if="item.qtype=='sharing'">
                <SendSharingF @Sendsharing="Sendsharing" :sender="sender" />
              </div>
              </div>
              </div>
              <div v-if="svdata[item.code] && item.qtype!='product' " class="text-right right-0 mt-2" :id="item.code">
                {{svdata[item.code]}}
              </div>
              <div v-else-if="item.qtype=='product'" class="text-right right-0 mt-2" :id="item.code">
              </div>
              <div v-else-if="item.qtype=='form'" class="text-right right-0 mt-2" :id="item.code">
              <div v-for="(sitem,index) in item.answer" :key="index">
              <div v-if="sitem.type=='7'" class="field-checkbox">
                {{svdata[sitem.code]}}
               </div>
               <div v-if="sitem.type=='2'||sitem.type=='3'" class="p-float-label">
              {{svdata[sitem.code]}}
               </div>
              <div v-if="sitem.type=='4'" >
              {{svdata[sitem.code]}}
              </div>
              </div>
              </div>
              <div v-else-if="item.qtype=='button'" class="text-right right-0 mt-2" :id="item.code">
                <div v-for="(sitem,index) in item.answer" :key="index">
                  {{svdata[sitem.code]}}
                </div>
              </div>
              <div v-if="loading" class=""></div>
              </div>
              <div class="absolute bottom-0 left-0 w-full">
              <small id="cb-ip-text" class="p-error">{{emessage}}</small>
                <div class="p-inputgroup w-full p-input-filled">
                    <InputText :placeholder="t('inputcontent')" class="w-full" v-model="ganswer" v-on:keyup.enter="next()"/>
                    <Button icon="pi pi-send" class="p-button-secondary" @click="next()"/>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import { ctntrack } from '@/api/tracking'
import SendSharingF from '@/components/sendsharing'
import Chip from 'primevue/chip'
import { formatCurrency } from '@/utils/fslist'
import { validEmail, validPhone, validURL } from '@/utils/validate'
import { sdChatbot, glChatbot, slChatbot } from '@/api/datasource'
import { useI18n } from 'vue-i18n'
export default {
  components: { InputText, Checkbox, Button, RadioButton, SendSharingF, Chip },
  props: {
    gcbcode: { type: String, default: 'lhmsp' },
    gnextstep: { type: String, default: '' },
    gdfdata: { type: Array },
    lobject: { type: Object },
    psend: { type: String, default: '' },
    esend: { type: String, default: '' }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      postForm: {},
      lpds: [],
      liwants: [],
      svdata: {},
      activeIndex: 0,
      date: null,
      accept: null,
      submitted: false,
      showMessage: false,
      ganswer: '',
      nextstep: '',
      emessage: '',
      tmpname: 'Bạn',
      sender: {},
      cbcode: '',
      cbitem: {},
      dfdata: [],
      lg: 'vi',
      lchatbots: [],
      lwchatbots: [],
      crshow: {},
      lQuery: {
        pageid: '',
        pagetitle: ''
      }
    }
  },
  validations () {
    return {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      accept: {
        required
      }
    }
  },
  watch: {
    psend: function (newvl, oldvl) {
      this.lQuery = newvl
    },
    esend: function (newvl, oldvl) {
      this.email = newvl
    },
    gcbcode: function (newvl, oldvl) {
      this.cbcode = newvl
    },
    gnextstep: function (newvl, oldvl) {
      this.nextstep = newvl
    },
    gdfdata: function (newvl, oldvl) {
      this.dfdata = newvl
    },
    lobject: function (newvl, oldvl) {
      this.lwchatbots.push(newvl)
    }
  },
  created () {
    this.lQuery = this.psend
    this.email = this.esend
    this.cbcode = this.gcbcode
    this.nextstep = this.gnextstep
    if (this.gdfdata && this.gdfdata.length > 0) {
      this.dfdata = this.gdfdata
    }
    if (this.lobject && this.lobject.code) {
      this.lwchatbots.push(this.lobject)
    }
    this.lg = sessionStorage.getItem('st-lg')
    this.gListSV()
  },
  methods: {
    async gListSV () {
      var gss = sessionStorage.getItem('st-acpage')
      const sendData = { cbcode: this.cbcode, rsession: gss, cbicode: this.nextstep, dfdata: this.dfdata, lg: this.lg }
      glChatbot(sendData).then((res) => {
        if (res && res.data.rdata) {
          this.lchatbots = res.data.rdata
          this.cbitem = res.data.qdata
          var vm = this
          this.lchatbots.forEach((item) => {
            if (item.qtype === 'product' || item.qtype === 'checkbox') {
              vm.svdata[item.code] = item.result ? item.result : []
            } else if (item.qtype === 'contact' || item.qtype === 'form' || item.qtype === 'button' || item.qtype === 'shopingcart') {
              item.answer.forEach((sitem) => {
                if (item.qtype !== 'shopingcart') {
                  vm.svdata[sitem.code] = sitem.result ? sitem.result : ''
                } else {
                  vm.svdata[sitem.code] = sitem.qty ? sitem.qty : 1
                  sitem.min = parseInt(sitem.min)
                }
              })
            }
          })
          this.crshow = this.lchatbots[0]
          this.lwchatbots.push(this.crshow)
          this.$nextTick(function () {
            var gindex = this.lwchatbots.length - 1
            const el = document.getElementById(this.lwchatbots[gindex].code)
            el.scrollIntoView({ block: 'start' })
          })
        }
      })
    },
    CuData (item, sitem, index, sindex) {
      if (this.svdata[sitem.code]) {
        var qty = parseInt(this.svdata[sitem.code])
        var rcprice = sitem.rcprice
        sitem.qty = qty
        if (sitem.pricegroup.length > 0) {
          sitem.pricegroup.forEach((pgi) => {
            if (qty >= parseInt(pgi.minuser) && qty <= parseInt(pgi.maxuser)) {
              rcprice = pgi.price
            }
          })
        }
        sitem.rcprice = rcprice
        sitem.totalprice = parseInt(qty) * parseFloat(sitem.rcprice) * parseInt(sitem.tg)
        this.lwchatbots[index].answer[sindex] = sitem
      }
    },
    nextbt (vl) {
      if (this.crshow.qtype === 'button' || this.crshow.code === vl.code || this.crshow.qtype === 'shopingcart') {
        if (this.crshow.qtype === 'shopingcart') {
          this.nextstep = this.cbitem.ldata.nextstep
        } else {
          this.nextstep = vl.nextstep
          if (this.crshow.qtype === 'button') {
            this.crshow.result = { code: vl.code, title: vl.title, bttype: vl.bttype }
            this.lwchatbots[this.lwchatbots.length - 1] = this.crshow
          }
        }
        this.svdata[vl.code] = (this.lg === 'en' && vl.titleen) ? vl.titleen : ((this.lg === 'de' && vl.titlede) ? vl.titlede : vl.title)
        this.gListSV()
      }
    },
    next () {
      var gss = sessionStorage.getItem('st-acpage')
      var vl = this.crshow
      this.emessage = ''
      if (this.crshow.qtype) {
        var tmprs = []
        var vm = this
        if (vl.qtype === 'shopingcart') {
          vl.answer.forEach((item) => {
            tmprs.push({ code: item.code, value: vm.svdata[item.code] })
          })
          vl.result = tmprs
        } else if (vl.qtype === 'contact' || vl.qtype === 'form') {
          vl.answer.forEach((item) => {
            tmprs.push({ code: item.code, value: vm.ganswer })
          })
          vl.result = tmprs
        } else {
          vl.result = this.svdata[vl.code]
        }
        var chkvalid = true
        if (this.ganswer) {
          this.svdata[vl.code] = this.ganswer
          if (vl.qtype === 'form') {
            chkvalid = this.ValidateField(vl.answer[0])
            if (vl.answer[0].rlcode === 'name') {
              this.tmpname = this.svdata[vl.code]
            }
          }
          this.ganswer = ''
        }
        if (chkvalid !== false) {
          this.lwchatbots[this.lwchatbots.length - 1] = vl
          const sendData = { cbcode: 'kskhmsp', ldata: this.lwchatbots, rsession: gss }
          slChatbot(sendData).then((res) => {
            var gindex = this.lchatbots.findIndex(v => v.code === this.crshow.code)
            if (gindex !== -1) {
              if (this.lchatbots[gindex + 1]) {
                this.crshow = this.lchatbots[gindex + 1]
                if (this.tmpname) {
                  var tmptitle = (this.lg === 'en' && this.crshow.titleen) ? this.crshow.titleen : this.crshow.title
                  tmptitle = tmptitle.replace('snames', this.tmpname)
                  this.crshow.title = (this.lg === 'en' && this.crshow.titleen) ? this.crshow.title : tmptitle
                  this.crshow.titleen = (this.lg === 'en' && this.crshow.titleen) ? tmptitle : this.crshow.titleen
                }
                this.lwchatbots.push(this.crshow)
                this.$nextTick(function () {
                  const el = document.getElementById(this.lwchatbots[this.lwchatbots.length - 1].code)
                  el.scrollIntoView({ block: 'start' })
                })
              } else if (this.cbitem.ldata.nextstep && this.cbitem.ldata.nextstep !== 'null') {
                this.nextstep = this.cbitem.ldata.nextstep
                if (this.crshow.qtype === 'product') {
                  if (this.svdata[this.crshow.code]) {
                    this.dfdata = this.svdata[this.crshow.code]
                    this.gListSV()
                  }
                } else {
                  this.gListSV()
                }
              } else {
                sdChatbot(sendData)
                var tmpdt = { code: 'ktr234232', title: this.t('thank') + ' ' + this.tmpname + ' ' + this.t('forsending') }
                this.lwchatbots.push(tmpdt)
                this.$nextTick(function () {
                  var lindex = this.lwchatbots.length - 1
                  const el = document.getElementById(this.lwchatbots[lindex].code)
                  el.scrollIntoView({ block: 'start' })
                  this.crshow = {}
                })
              }
            }
          })
        } else {
          if (parseInt(this.crshow.answer[0].valid) === 2) {
            this.emessage = (this.lg === 'en') ? 'Email is incorrect' : 'Email không chính xác!'
          }
          if (parseInt(this.crshow.answer[0].valid) === 3) {
            this.emessage = (this.lg === 'en') ? 'Phone is incorrect' : 'Số điện thoại không chính xác!'
          }
          if (parseInt(this.crshow.answer[0].valid) === 4) {
            this.emessage = (this.lg === 'en') ? 'Website is not in the correct format, please enter it correctly!' : 'Website chưa đúng định dạng, vui lòng nhập đúng!'
          }
          if (this.svdata[this.crshow.code].includes('script') || this.svdata[this.crshow.code].includes('body') || this.svdata[this.crshow.code].includes('img') || this.svdata[this.crshow.code].includes('iframe') || this.svdata[this.crshow.code].includes('input') || this.svdata[this.crshow.code].includes('link') || this.svdata[this.crshow.code].includes('table') || this.svdata[this.crshow.code].includes('object')) {
            this.emessage = (this.lg === 'en') ? 'Website is not in the correct format' : 'Thông tin không đúng định dạng!'
          }
        }
      } else {
        this.svdata = {}
        this.ganswer = ''
        this.nextstep = ''
        this.sender = {}
        this.cbitem = {}
        this.dfdata = []
        this.lchatbots = []
        this.lwchatbots = []
        this.gListSV()
      }
    },
    prev () {
      this.activeIndex = (this.activeIndex === 0) ? 0 : this.activeIndex - 1
    },
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var gtdata = { rsession: gss, pageid: this.lQuery.pageid, pagetitle: this.lQuery.pagetitle, elementid: vl.eid }
      ctntrack(gtdata)
    },
    ValidateField (vl) {
      if (this.ganswer.includes('script') || this.ganswer.includes('body') || this.ganswer.includes('img') || this.ganswer.includes('iframe') || this.ganswer.includes('input') || this.ganswer.includes('link') || this.ganswer.includes('table') || this.ganswer.includes('object')) {
        return false
      }
      switch (parseInt(vl.valid)) {
        case 0:
        case 1:
          if (!this.ganswer) {
            return false
          } else {
            return true
          }
        case 2:
          return this.validEmail(this.ganswer)
        case 3:
          return this.validPhone(this.ganswer)
        case 4:
          return this.validURL(this.ganswer)
      }
      return vl.valid
    },
    validEmail,
    validPhone,
    validURL,
    formatCurrency
  }
}
</script>
<style>
#l-survey .p-galleria-item{
  display: block !important;
}
</style>

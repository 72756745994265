import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02811d64"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid w-full"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionB = _resolveComponent("SectionB")!
  const _component_Block = _resolveComponent("Block")!

  return (_ctx.gdata.iscols && _ctx.lcols)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lcols, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass('flex col-12 md:col-'+item)
          }, [
            (_ctx.gdata.gdata[index].gdata.length>0)
              ? (_openBlock(), _createBlock(_component_SectionB, {
                  key: 0,
                  gdata: _ctx.gdata.gdata[index],
                  page: _ctx.page,
                  lang: _ctx.lang,
                  onOpencontact: _ctx.opencontact,
                  onOpenshopcart: _ctx.openshopcart,
                  onUtracking: _ctx.utracking,
                  onOpenchatbot: _ctx.openchatbot
                }, null, 8, ["gdata", "page", "lang", "onOpencontact", "onOpenshopcart", "onUtracking", "onOpenchatbot"]))
              : (_openBlock(), _createBlock(_component_Block, {
                  key: 1,
                  gdata: _ctx.gdata.gdata[index],
                  page: _ctx.page,
                  lang: _ctx.lang,
                  onOpencontact: _ctx.opencontact,
                  onOpenshopcart: _ctx.openshopcart,
                  onUtracking: _ctx.utracking,
                  onOpenchatbot: _ctx.openchatbot
                }, null, 8, ["gdata", "page", "lang", "onOpencontact", "onOpenshopcart", "onUtracking", "onOpenchatbot"]))
          ], 2))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gdata.gdata, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "grid"
          }, [
            (item.gdata.length>0)
              ? (_openBlock(), _createBlock(_component_SectionB, {
                  key: 0,
                  gdata: item,
                  page: _ctx.page,
                  lang: _ctx.lang,
                  onOpencontact: _ctx.opencontact,
                  onOpenshopcart: _ctx.openshopcart,
                  onUtracking: _ctx.utracking,
                  onOpenchatbot: _ctx.openchatbot
                }, null, 8, ["gdata", "page", "lang", "onOpencontact", "onOpenshopcart", "onUtracking", "onOpenchatbot"]))
              : (_openBlock(), _createBlock(_component_Block, {
                  key: 1,
                  gdata: item,
                  page: _ctx.page,
                  lang: _ctx.lang,
                  onOpencontact: _ctx.opencontact,
                  onOpenshopcart: _ctx.openshopcart,
                  onUtracking: _ctx.utracking,
                  onOpenchatbot: _ctx.openchatbot
                }, null, 8, ["gdata", "page", "lang", "onOpencontact", "onOpenshopcart", "onUtracking", "onOpenchatbot"]))
          ]))
        }), 128))
      ]))
}

import { defineAsyncComponent, defineComponent } from 'vue'
import { lBvCat } from '@/api/blog'
import { gProduct } from '@/api/product'
import { useI18n } from 'vue-i18n'
import Skeleton from 'primevue/skeleton'
const MenuBar = defineAsyncComponent(() =>
  import('primevue/menubar')
)
const Dropdown = defineAsyncComponent(() =>
  import('primevue/dropdown')
)
const AutoComplete = defineAsyncComponent(() =>
  import('primevue/autocomplete')
)

export default defineComponent({
  components: {
    MenuBar,
    Dropdown,
    AutoComplete,
    searchpage: defineAsyncComponent(() => import('@/views/Search.vue')),
    Skeleton
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      vs: false,
      stext: '',
      loading: false,
      selectedLg: { name: 'English', code: 'en', img: '/img/flags/us.jpg' },
      languages: [
        { name: 'Vietnamese', code: 'vi', img: '/img/flags/vn.jpg' },
        { name: 'English', code: 'en', img: '/img/flags/us.jpg' },
        { name: 'Germany', code: 'de', img: '/img/flags/de.jpg' },
        { name: 'Hungary', code: 'hu', img: '/img/flags/hu.jpg' }
      ],
      url: '',
      lg: 'en',
      filteredSearchBasic: [],
      lsuggestions: ['phần mềm quản lý chiến lược', 'phần mềm quản lý hiệu suất', 'phần mềm quản lý khách hàng', 'hrm', 'crm'],
      lblogs: [],
      items: [
        { label: this.t('menu.gioithieu'), to: '/', items: [] },
        { label: this.t('menu.giathanh'), to: '/#price' },
        { label: this.t('menu.congty'), to: '/cong-ty' },
        { label: this.t('menu.blog'), to: '/blog', items: [] },
        { label: this.t('menu.dangnhap'), url: 'https://app.uselfbank.com' }
      ]
    }
  },
  created () {
    var glg: any = sessionStorage.getItem('st-lg')
    this.lg = glg
    var gid = this.languages.findIndex((v: any) => v.code === glg)
    if (gid !== -1) {
      this.selectedLg = this.languages[gid]
    }
    this.gLProducts()
    this.glBlogmenus()
  },
  methods: {
    tgsearch () {
      this.vs = true
    },
    searchPrd (event: any) {
      var tmpvl: any = []
      this.lsuggestions.forEach((item: any) => {
        if (item.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          tmpvl.push(item)
        }
        this.filteredSearchBasic = tmpvl
      })
    },
    glBlogmenus () {
      lBvCat({ lang: this.lg }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.forEach((item: any) => {
            tmp.push({ key: item.id, label: item.title, to: '/' + this.lg + '/blog/danh-muc/' + item.slug + '-' + item.code })
          })
          this.items.forEach((sit: any) => {
            if (sit.to === '/blog') {
              sit.items = tmp
            }
          })
        }
      })
    },
    async gLProducts () {
      this.loading = true
      const sendData = { slug: 'phan-mem-920220316041318', lg: this.lg }
      await this.$store.dispatch('product/l', sendData).then(async (res: any) => {
        if (res) {
          this.url = res.data.url
          var tmp: any = []
          res.data.data.data.forEach((item: any) => {
            tmp.push({ key: item.id, label: item.title + ' (' + item.shorttitle + ')', to: '/san-pham/' + item.slug + '-' + item.code })
          })
          this.items.forEach((sit: any) => {
            if (sit.to === '/') {
              sit.items = tmp
            }
          })
        }
        this.loading = false
      })
    },
    sllg (vl: any) {
      sessionStorage.setItem('st-lg', vl.value.code)
      location.reload()
    },
    close () {
      this.vs = false
    }
  }
})

import { createI18n, I18nOptions } from 'vue-i18n'
import vi from './vn-VI.json'
import en from './en-US.json'
import de from './de-DE.json'
import hu from './hu-HU.json'

export function getLanguage () {
  const chooseLanguage = sessionStorage.getItem('st-lg')
  if (chooseLanguage) {
    return chooseLanguage
  }
  // if has not choose language
  return 'en'
}
type MessageSchema = typeof vi

const i18n = createI18n<I18nOptions, [MessageSchema], 'vi' | 'en' | 'de' | 'hu'>({
  legacy: false,
  locale: getLanguage(),
  fallbackLocale: 'en',
  messages: {
    hu,
    de,
    en,
    vi
  }
})
export default i18n

<template>
 <Dialog v-model:visible="sct" :style="{ width: '100vw' }" position="bottom" :modal="true" :showHeader="false">
 <span>{{t('accept_cookie')}}</span>
 <div class="mt-2">
 <Btn :label="t('tu_choi')" outlined class="mx-2 p-button-outlined" @click="Close" />
 <Btn :label="t('chap_nhan')" class="p-button-warning" @click="acceptd" />
 </div>
 </Dialog>
</template>
<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Dialog from 'primevue/dialog'
import Btn from 'primevue/button'
export default defineComponent({
  components: { Dialog, Btn },
  props: {
    sdt: { type: Boolean, default: true }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      sct: true
    }
  },
  created () {
    this.sct = this.sdt
  },
  watch: {
    sdt: {
      handler: function (newvl) {
        this.sct = newvl
      },
      immediate: true
    }
  },
  methods: {
    Close () {
      this.sct = false
    },
    acceptd () {
      localStorage.setItem('acp-cookie', true)
      this.sct = false
    }
  }
})
</script>

<template>
<div class="bottom-0">
  <div class="seopath">
  <img src="/img/UselfBank_300px.png" alt="uselfbank" title="uselfbank" width="300" height="300"/>
  </div>
<Toast position="top-center" class="bg-orange-200"/>
  <router-view @opencontact="opencontact" @openshopcart="openshopcart" @loadfooter="loadfooter" @stopshowsurvey="stopshowsurvey" @showMessage="showMessage" />
  <LeftFloat @utracking="utracking" />
  <RightFloat v-if="!cla" @utracking="utracking" />
  <CcK :sdt="!acp"/>
  <Dialog  v-model:visible="dsp" ref="ctform" :modal="true" @hide="closecontact">
  <ContactFormdnm :psend="psend" :esend="esend" @close="closecontact" @showMessage="showMessage"/>
  </Dialog>
  <Dialog  v-if="!cla" v-model:visible="dspsv" :breakpoints="{ '1024px': '50vw', '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" ref="svform" :modal="true" @hide="closecontact">
  <SurveyFloat :psend="psend" @close="closecontact" @donesurvey="donesurvey" />
  </Dialog>
  <ChatBot v-if="!cla" @stopshowsurvey="stopshowsurvey"/>
  <LeftCornerFloat  v-if="!cla" />
  <GiohangFloat  v-if="!cla" :lang="lang" ref="ghfloat" @utracking="utracking" />
</div>
</template>
<script type="ts">
import { defineAsyncComponent } from 'vue'
import Dialog from 'primevue/dialog'
import CcK from '@/layouts/other/checkcookie.vue' // @ is an alias to /src
import LeftFloat from '@/layouts/other/leftfloat.vue' // @ is an alias to /src
import RightFloat from '@/layouts/other/rightfloat.vue' // @ is an alias to /src
import ChatBot from '@/layouts/other/chatbot.vue' // @ is an alias to /src
import LeftCornerFloat from '@/layouts/other/leftcornerfloat.vue'
import { sdSurvey } from '@/api/datasource'
import { ctntrack } from '@/api/tracking'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
export default {
  components: {
    SurveyFloat: defineAsyncComponent(() => import('@/layouts/other/surveyfloat.vue')),
    LeftFloat,
    RightFloat,
    ChatBot: defineAsyncComponent(() => import('@/layouts/other/chatbot.vue')),
    Dialog: defineAsyncComponent(() => import('primevue/dialog')),
    LeftCornerFloat,
    CcK,
    ContactFormdnm: defineAsyncComponent(() => import('@/layouts/other/contactfloatdnm.vue')),
    Toast,
    GiohangFloat: defineAsyncComponent(() => import('@/layouts/other/giohangfloat.vue'))
  },
  setup () {
    const toast = useToast()
    return { msg: toast }
  },
  data () {
    return {
      cla: true,
      dsp: false,
      dspsv: false,
      atpopup: true,
      tmptime: null,
      svdone: false,
      lang: 'vi',
      localTime: '',
      acp: false,
      psend: {},
      esend: ''
    }
  },
  created () {
    this.$nextTick(() => {
      this.lang = sessionStorage.getItem('st-lg') ? sessionStorage.getItem('st-lg') : 'vi'
      this.acp = localStorage.getItem('acp-cookie') ? localStorage.getItem('acp-cookie') : false
      window.addEventListener('scroll', this.showLocaleTime)
    })
  },
  methods: {
    showLocaleTime: function () {
      var vm = this
      clearInterval(this.tmptime)
      this.tmptime = setInterval(function () {
        if (vm.atpopup && !vm.svdone) {
          vm.dspsv = true
        }
      }, 9000)
    },
    donesurvey (vl) {
      if (vl) {
        this.svdone = true
        var gss = sessionStorage.getItem('st-acpage')
        const sendData = { svcode: 'kskhmsp', rsession: gss }
        sdSurvey(sendData).then((res) => {
          this.closecontact(3)
        })
      }
    },
    stopshowsurvey () {
      this.atpopup = false
      clearInterval(this.tmptime)
    },
    opencontact (vl) {
      this.psend = vl
      this.esend = vl.email
      this.dsp = true
      this.atpopup = false
      clearInterval(this.tmptime)
    },
    closecontact (gd) {
      this.dsp = false
      this.dspsv = false
      clearInterval(this.tmptime)
      if (!this.svdone) {
        this.atpopup = true
      }
      if (gd === 3) {
        this.$router.replace('/Sharing')
      }
    },
    openshopcart (vl) {
      this.atpopup = false
      clearInterval(this.tmptime)
      this.$refs.ghfloat.openshopcart(vl)
    },
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var to = this.$route
      var ptitle = to.meta.title
      if (vl.pagetitle) {
        ptitle = vl.pagetitle
      }
      var gtdata = { rsession: gss, pageid: to.path, pagetitle: ptitle, elementid: vl.eid }
      ctntrack(gtdata)
    },
    showMessage (ginfo, gtype) {
      var msgtype = 'success'
      switch (gtype) {
        case 1:
          msgtype = 'success'
          break
        case 2:
          msgtype = 'warning'
          break
        case 3:
          msgtype = 'danger'
          break
      }
      this.msg.add({ severity: msgtype, summary: ginfo.title, detail: ginfo.data, life: 5000 })
    },
    loadfooter () {
      this.$emit('sload', true)
    }
  }
}
</script>
<style>
  .seopath {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
</style>

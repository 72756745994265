<template>
  <div>
  <template v-if="code=='fgemaasdf'">
  <form @submit.prevent="ghsm(!v$.$invalid)" class="p-fluid" id="contact-form">
    <Toast position="top-center" class="bg-orange-200"/>
    <div class="p-inputgroup">
      <IpT @focus="utracking('he1l')" :placeholder="t('sform.vlnhapemail')" id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
      <Button icon="pi pi-send" class="p-button-warning" @click="utracking('heb1l')" :label="title" type="submit"/>
    </div>
  </form>
  </template>
  <template v-else-if="code=='fgctfsdfsd'">
  <div class="card">
    <div class="flex align-items-center justify-content-center font-bold m-2" >{{ title }}</div>
    <div class="flex align-items-center justify-content-center">
    <IpT :placeholder="t('sform.hovaten')" class="w-full m-3"  @click="opencontact(tkcode)" aria-label="Họ và tên"/>
    </div>
    <div class="flex align-items-center justify-content-center">
    <IpT placeholder="Email"  class="w-full m-3"  @click="opencontact(tkcode)" aria-label="Email"/>
    </div>
    <div class="flex align-items-center justify-content-center" >
    <IpT :placeholder="t('sform.dienthoai')" class="w-full m-3"   @click="opencontact(tkcode)" aria-label="Phone"/>
    </div>
    <div class="flex align-items-center justify-content-center">
    <Button class="w-full m-3 p-button-warning" :label="title" @click="opencontact(tkcode)" />
    </div>
</div>
  </template>
  <template v-else-if="code=='ssdfsdfasd'">
  <div>
  {{t('sform.toimuon')}}: <SplitButton aria-label="Tôi muốn" :model="lTMs" :label="lTMs[0].label" style="width:320px;" class="p-button-warning"  @click="opencontact(tkcode)"/>
  </div>
  </template>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { ref, reactive, defineComponent } from 'vue'
import IpT from 'primevue/inputtext'
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import InlineMessage from 'primevue/inlinemessage'
import { sclmDatasource, lDatasource } from '@/api/datasource'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { Toast, IpT, Button, SplitButton },
  props: {
    code: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { t } = useI18n()
    const state = reactive({
      email: ''
    })
    const rules = {
      email: { required, email }
    }
    const toast = useToast()
    const submitted = ref(false)
    const showMessage = ref(false)
    const v$ = useVuelidate(rules, state)
    const handleSubmit = (isFormValid) => {
      submitted.value = true
      if (!isFormValid) {
        toast.add({ severity: 'warning', summary: t('msg.tb'), detail: t('msg.dckcx'), life: 3000 })
        return false
      }
      toggleDialog()
      return true
    }
    const toggleDialog = () => {
      showMessage.value = !showMessage.value
      if (!showMessage.value) {
        resetForm()
      }
    }
    const resetForm = () => {
      state.email = ''
      submitted.value = false
    }
    return { state, v$, handleSubmit, toggleDialog, submitted, showMessage, t }
  },
  data () {
    return {
      selectedTM: 'baogia',
      lTMs: [
        { label: 'Báo giá phần mềm KPIBSC', code: 'baogia', command: () => { this.opencontact(this.tkcode) } }
      ],
      lQuery: {
        code: 'iwant',
        lg: 'vi'
      }
    }
  },
  created () {
    this.lQuery.lg = sessionStorage.getItem('st-lg')
    if (this.code === 'ssdfsdfasd') {
      this.gtdata()
    }
  },
  methods: {
    utracking (vl) {
      this.$emit('utracking', { eid: vl })
    },
    opencontact (vl) {
      this.$emit('utracking', { eid: vl })
      var dvl = { eid: vl }
      this.$emit('opencontact', dvl)
    },
    gtdata () {
      lDatasource({ code: 'iwant' }).then((res) => {
        if (res) {
          var tmpvl = []
          var vm = this
          res.data.rdata.forEach((item) => {
            var title = item.ldata.title
            if (this.lQuery.lg !== 'vi' && item.ldata['title' + this.lQuery.lg]) {
              title = item.ldata['title' + this.lQuery.lg]
            }
            var tmpvl1 = { label: title, code: item.code, command: () => { vm.opencontact(vm.tkcode) } }
            tmpvl.push(tmpvl1)
          })
          this.lTMs = tmpvl
        }
      })
    },
    ghsm (vl) {
      if (this.handleSubmit(vl)) {
        sclmDatasource(this.state).then((res) => {
          var dvl = { eid: this.tkcode, email: this.state.email }
          this.$emit('opencontact', dvl)
        })
      }
    }
  }
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
.p-tieredmenu li {
  display: block;
  margin: 10px;
}
a {
  color: #42b983;
}
.p-splitbutton .p-button{
  background-color: #b98801 !important;
  color: black !important;
  border: none !important
}
</style>


import { Options, Vue } from 'vue-class-component'
import Block from './block.vue'
@Options({
  name: 'SectionB',
  components: { Block },
  props: {
    gdata: {
      type: Object
    },
    page: {
      type: String,
      default: 'home'
    },
    lang: {
      type: String,
      default: 'vi'
    }
  },
  computed: {
    lcols: function () {
      var vl = []
      if (this.gdata.tlcols !== '') {
        vl = this.gdata.tlcols.split(':')
        if (this.gdata.gdata.length < vl.length) {
          var tmpvl = []
          for (var i = 0; i < this.gdata.gdata.length; i++) {
            tmpvl.push(Math.ceil(12 / (this.gdata.gdata.length)))
          }
        }
      }
      return vl
    }
  },
  methods: {
    utracking (vl: any) {
      this.$emit('utracking', vl)
    },
    opencontact (vl: any) {
      this.$emit('opencontact', vl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    },
    openchatbot (vl: any, dt: any) {
      this.$emit('openchatbot', vl, dt)
    }
  }
})
export default class HelloWorld extends Vue {
  tlcols!: string
}


import { Options, Vue } from 'vue-class-component'
import { defineAsyncComponent } from 'vue'
import sProduct from './sproduct.vue'
import sForm from './sform.vue'
@Options({
  components: {
    sButton: defineAsyncComponent(() => import('./sbutton.vue')),
    sProduct,
    sForm,
    sChatbot: defineAsyncComponent(() => import('./schatbot.vue')),
    sKhachhang: defineAsyncComponent(() => import('./skhachhang.vue')),
    sPrice: defineAsyncComponent(() => import('./sprice.vue')),
    sCard: defineAsyncComponent(() => import('./scard.vue'))
  },
  props: {
    gdata: {
      type: Object
    },
    page: {
      type: String,
      default: 'home'
    },
    lang: {
      type: String,
      default: 'vi'
    }
  },
  data () {
    return {
      showcontent: '',
      lQuery: {
        page: 'home',
        lang: 'vi'
      }
    }
  },
  mounted () {
    var hsh = this.$route.hash
    if (this.gdata.content) {
      var tmpstr = this.gdata.content.split('<nsprn>')
      if (tmpstr.length > 0) {
        var rdid = Math.floor(Math.random() * tmpstr.length)
        this.showcontent = tmpstr[rdid]
      } else {
        this.showcontent = this.gdata.content
      }
    }
  },
  methods: {
    utracking (vl: any) {
      this.$emit('utracking', vl)
    },
    opencontact (vl: any) {
      this.$emit('opencontact', vl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    },
    openchatbot (vl: any, dt: any) {
      this.$emit('openchatbot', vl, dt)
    }
  }
})
export default class Block extends Vue {
  msg!: string
}

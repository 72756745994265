
import { Options, Vue } from 'vue-class-component'
import { defineComponent } from 'vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { useI18n } from 'vue-i18n'
import { sshareDatasource } from '@/api/datasource'
import { validEmail } from '@/utils/validate'

export default defineComponent({
  name: 'Sharingdata',
  components: { InputText, Button, Toast },
  props: {
    sender: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      gForm: { name: '', email: '' },
      toast: useToast(),
      lmails: [{ m: '' }, { m: '' }, { m: '' }],
      lQuery: {
        pageid: 'home',
        pagetitle: 'vi'
      }
    }
  },
  watch: {
    sender: {
      handler (newvl) {
        this.gForm.name = newvl.name
        this.gForm.email = newvl.email
      },
      immediate: true
    }
  },
  methods: {
    utracking (vl: any) {
      this.$emit('utracking', vl)
    },
    addEmail () {
      this.lmails.push({ m: '' })
    },
    validEmail,
    Sendsharing () {
      if (this.gForm && this.gForm.name && this.gForm.email) {
        var chkempty = true
        var chkvalid = true
        var vm = this
        this.lmails.forEach((item: any) => {
          if (item.m) {
            chkempty = false
            if (!vm.validEmail(item.m)) {
              chkvalid = false
            }
          }
        })
        if (chkempty || !chkvalid) {
          this.toast.add({ severity: 'warning', summary: this.t('notice'), detail: this.t('inputemail'), life: 3000 })
        } else {
          sshareDatasource(this.sender).then((res) => {
            this.toast.add({ severity: 'success', summary: this.t('notice'), detail: this.t('thank_for_sharing'), life: 3000 })
          })
        }
      }
    }
  }
})

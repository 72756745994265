<template>
<div>
  <div v-for="( item, index ) in lsections" :key="index" :class="'pt-5 pb-5 grid '+item.class">
    <div class="hidden md:inline-flex md:col-1 lg:col-1 xl:col-1"></div>
    <div class="col-12 md:col-10 lg:col-10 xl:col-10">
    <Section  :gdata="item" :page="lQuery.page" :lang="lQuery.lang" @opencontact="opencontact" @openshopcart="openshopcart" @utracking="utracking" @showMessage="showMessage" />
    </div>
    <div class="hidden md:inline-flex md:col-1 lg:col-1 xl:col-1"></div>
  </div>
</div>
</template>

<script>
import Section from '@/components/section.vue' // @ is an alias to /src
import ajson from '@/components/json/seodata.json'
import { ctntrack } from '@/api/tracking'
import { glSection } from '@/api/section'
export default {
  components: {
    Section
  },
  data () {
    return {
      lpages: [{ code: 'home', slug: '' }, { code: 'congty', slug: 'cong-ty' }],
      lQuery: {
        domain: 'kpibsc.com',
        page: 'home',
        lang: 'vi',
        sscode: []
      },
      gstart: 0,
      title: '',
      seodata: ['kpibsc.com nền tảng quản trị xuất sắc'],
      lsdata: [],
      lsections: []
    }
  },
  created () {
    this.lQuery.lang = sessionStorage.getItem('st-lg')
    var gpath = this.$route.path.split('/')
    // this.seodata = ajson.data
    this.lQuery.domain = window.location.host
    this.gpage(gpath)
  },
  watch: {
    $route: function (newvl, oldvl) {
      var gpath = newvl.path.split('/')
      this.lQuery.lang = sessionStorage.getItem('st-lg')
      this.gstart = 0
      this.lsdata = []
      this.lsections = []
      this.gpage(gpath)
    }
  },
  mounted () {
    this.getNextDt()
  },
  methods: {
    getNextDt () {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= 150
        if (bottomOfWindow && this.gstart > 0) {
          this.gList()
        }
      }
    },
    gList () {
      var tmpdata = []
      var tmpdata1 = []
      var stvl = 0
      if (this.lsdata && this.gstart < 2) {
        this.lsdata.forEach((item) => {
          if (stvl < 2) {
            tmpdata.push(item.code)
          } else {
            tmpdata1.push(item.code)
          }
          stvl += 1
        })
        if (this.gstart === 0) {
          this.lQuery.sscode = tmpdata
        } else {
          this.lQuery.sscode = tmpdata1
        }
        this.gstart += 1
        if (this.lQuery.sscode.length > 0) {
          this.$store.dispatch('section/l', this.lQuery).then((res) => {
            this.lsections = this.lsections.concat(res.data)
            this.$emit('loadfooter')
          })
        }
      }
    },
    gpage (vl) {
      this.lsections = []
      var tmp = 'home'
      if (vl.length > 0) {
        if (vl[1] !== '') {
          var fid = this.lpages.findIndex((v) => v.slug === vl[1])
          if (fid !== -1) {
            tmp = this.lpages[fid].code
          }
        }
      }
      this.lQuery.page = tmp
      glSection(this.lQuery).then((res) => {
        this.lsdata = res.data
        this.gList()
      })
    },
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var to = this.$route
      var gtdata = { rsession: gss, pageid: to.path, pagetitle: to.meta.title, elementid: vl.eid }
      ctntrack(gtdata)
    },
    opencontact (vl) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    openshopcart (vl) {
      this.$emit('openshopcart', vl)
    },
    showMessage (vl, t) {
      this.$emit('showMessage', vl, t)
    }
  }
}
</script>
<style>
.section{
  padding:40px 0px;
}
</style>

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 6,
  class: "text-4xl font-bold no-underline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sButton = _resolveComponent("sButton")!
  const _component_sForm = _resolveComponent("sForm")!
  const _component_sProduct = _resolveComponent("sProduct")!
  const _component_sKhachhang = _resolveComponent("sKhachhang")!
  const _component_sPrice = _resolveComponent("sPrice")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sCard = _resolveComponent("sCard")!
  const _component_sChatbot = _resolveComponent("sChatbot")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.gdata.class)
  }, [
    (_ctx.gdata.istype=='text')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          innerHTML: _ctx.showcontent
        }, null, 8, _hoisted_1))
      : (_ctx.gdata.istype=='button')
        ? (_openBlock(), _createBlock(_component_sButton, {
            key: 1,
            code: _ctx.gdata.content,
            tkcode: _ctx.gdata.tkcode,
            title: _ctx.gdata.title,
            onOpencontact: _ctx.opencontact,
            onUtracking: _ctx.utracking
          }, null, 8, ["code", "tkcode", "title", "onOpencontact", "onUtracking"]))
        : (_ctx.gdata.istype=='form')
          ? (_openBlock(), _createBlock(_component_sForm, {
              key: 2,
              code: _ctx.gdata.content,
              tkcode: _ctx.gdata.tkcode,
              title: _ctx.gdata.title,
              onOpencontact: _ctx.opencontact,
              onUtracking: _ctx.utracking
            }, null, 8, ["code", "tkcode", "title", "onOpencontact", "onUtracking"]))
          : (_ctx.gdata.istype=='product')
            ? (_openBlock(), _createBlock(_component_sProduct, {
                key: 3,
                code: _ctx.gdata.content,
                tkcode: _ctx.gdata.tkcode,
                title: _ctx.gdata.title,
                onOpenshopcart: _ctx.openshopcart,
                onUtracking: _ctx.utracking
              }, null, 8, ["code", "tkcode", "title", "onOpenshopcart", "onUtracking"]))
            : (_ctx.gdata.istype=='showkh')
              ? (_openBlock(), _createBlock(_component_sKhachhang, {
                  key: 4,
                  code: _ctx.gdata.content,
                  title: _ctx.gdata.title
                }, null, 8, ["code", "title"]))
              : (_ctx.gdata.istype=='pricetable')
                ? (_openBlock(), _createBlock(_component_sPrice, {
                    key: 5,
                    code: _ctx.gdata.content,
                    tkcode: _ctx.gdata.tkcode,
                    title: _ctx.gdata.title,
                    page: _ctx.page,
                    ref: "sprice",
                    onOpenshopcart: _ctx.openshopcart,
                    onUtracking: _ctx.utracking
                  }, null, 8, ["code", "tkcode", "title", "page", "onOpenshopcart", "onUtracking"]))
                : (_ctx.gdata.istype=='link')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_router_link, {
                        to: '/cong-ty',
                        class: "no-underline"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.gdata.title), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_ctx.gdata.istype=='card')
                    ? (_openBlock(), _createBlock(_component_sCard, {
                        key: 7,
                        content: _ctx.gdata.content,
                        bgcolor: _ctx.gdata.bgcolor,
                        tkcode: _ctx.gdata.tkcode,
                        title: _ctx.gdata.title,
                        onOpencontact: _ctx.opencontact,
                        onUtracking: _ctx.utracking
                      }, null, 8, ["content", "bgcolor", "tkcode", "title", "onOpencontact", "onUtracking"]))
                    : (_ctx.gdata.istype=='chatbot')
                      ? (_openBlock(), _createBlock(_component_sChatbot, {
                          key: 8,
                          code: _ctx.gdata.content,
                          tkcode: _ctx.gdata.tkcode,
                          title: _ctx.gdata.title,
                          onOpenshopcart: _ctx.openshopcart,
                          onUtracking: _ctx.utracking,
                          onOpenchatbot: _ctx.openchatbot
                        }, null, 8, ["code", "tkcode", "title", "onOpenshopcart", "onUtracking", "onOpenchatbot"]))
                      : _createCommentVNode("", true)
  ], 2))
}

import { defineComponent } from 'vue'
import Bt from 'primevue/button'
import DtView from 'primevue/dataview'
import Rt from 'primevue/rating'
import { lProduct } from '@/api/product'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { Bt, DtView, Rt },
  props: {
    code: {
      type: String,
      default: ''
    },
    pageid: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    srow: {
      type: Number,
      default: 6
    }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      lpds: [],
      layout: 'list',
      setrow: 6,
      url: '',
      gslug: '',
      tkvl: '',
      iconPos: 'left',
      sortKey: null,
      sortOrder: -1,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ratingvl: 5
    }
  },
  created () {
    this.tkvl = this.tkcode
    this.gList()
  },
  watch: {
    slug: function (nvl, oldvl) {
      return nvl
    },
    tkcode: function (nvl, oldvl) {
      return nvl
    }
  },
  mounted () {
    this.tkvl = this.tkcode
  },
  methods: {
    async gList () {
      var lg = sessionStorage.getItem('st-lg')
      const sendData = { slug: this.slug, lg: lg }
      if (document.documentElement.offsetWidth < 540) {
        this.iconPos = 'top'
      }
      await lProduct(sendData).then(async (res: any) => {
        if (res) {
          this.lpds = res.data.data.data
          this.url = res.data.url
        }
      })
    },
    openshopcart (vl: any) {
      var dtvl = { pageid: this.pageid, pdcode: vl, stype: 0, nuser: 10 }
      this.$emit('utracking', { eid: this.tkvl })
      this.$emit('openshopcart', dtvl)
    }
  }
})
